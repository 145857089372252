<template>
  <span class="" v-if="blocked">
    <v-menu offset-y :nudge-bottom="2">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :color="area.cor" class="text-nonecase fs-8pt pt-1 px-1 me-1" v-bind="attrs" v-on="on" outlined x-small>
          <i v-i:ic#download#18 class="btn-icon-left"></i>
          <span>exportar</span>
        </v-btn>
      </template>
      <v-card>
        <v-list dense class="m-0 p-0">
          <v-list-item dense class="m-0 px-1" style="min-height: 26px;">
            <v-btn @click="exportarPlanilha" :color="area.cor" x-small text class="m-0 p-0 text-nonecase line-height-0 f-lato fs-10pt">
              <i v-i:duo#download#16 class="btn-icon-left ms-0"></i>
              Planilha
            </v-btn>
          </v-list-item>
          <v-list-item dense class="m-0 px-1" style="min-height: 26px;">
            <v-btn disabled :color="area.cor" x-small text class="m-0 p-0 text-nonecase line-height-0 f-lato fs-10pt">
              <i v-i:duo#download#16 class="btn-icon-left ms-0"></i>
              Documento
            </v-btn>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </span>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import XLSX from 'xlsx';
import moment from 'moment';
import apploading from './appLoading.vue';

export default {
  name: "verplanoensino",
  components: {
    apploading,
    'ementaviewitem': () => import('@/views/cursos/planosv2/ementa/ementa-view-item.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: {
    blocked() {
      if(this.area != null) {
        var gestores = ["Gestor(a) de Área","Assessor(a) de Área","Coordenador(a) Regional"]
        if(this.isUserAdmin) return true;
        if(gestores.indexOf(this.userLog.cargo) != -1 && this.userLog.areasIC[this.area.id] != undefined) {
          return true;
        }
      }
      return false;
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      conteudos: {},
      qtdeConteudos: 0,
      topicos: {},
      qtdeTopicos: 0,
      ordem: "",
      metas: {},
      qtdeMetas: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    console.log("this.uc.id",this.uc.id);
    rdb.ref('/curriculo/conteudos/ucs/'+this.uc.id).on('value',function(snapshot) {
      var linha = snapshot.val();
      if(linha == null) {
        self.conteudos = {};
        self.qtdeConteudos = 0;
        self.ordem = "";
      } else {
        self.qtdeConteudos = linha.qtde;
        self.ordem = linha.ordem;
        self.conteudos = {};
        var vetOrdem = [];
        if(linha.ordem != undefined) {
          vetOrdem = linha.ordem.split(";");
        }
        for(var idx in vetOrdem) {
          self.conteudos[vetOrdem[idx]] = linha.conteudos[vetOrdem[idx]];
        }
      }
      self.refresh++;
      console.log("self.conteudos",self.conteudos);
    });

    rdb.ref('/curriculo/topicos/ucs/'+this.uc.id).on('value',function(snapshot) {
      var linha = snapshot.val();
      if(linha == null) {
        self.topicos = {};
        self.qtdeTopicos = 0;
        self.ordem = "";
      } else {
        self.qtdeTopicos = linha.qtde;
        self.ordem = linha.ordem;
        self.topicos = {};
        var vetOrdem = [];
        if(linha.ordem != undefined) {
          vetOrdem = linha.ordem.split(";");
        }
        for(var idx in vetOrdem) {
          self.topicos[vetOrdem[idx]] = linha.topicos[vetOrdem[idx]];
        }
      }
      self.refresh++;
      console.log("self.topicos",self.topicos);
    });

    rdb.ref("/curriculo/metasNovas/").orderByChild("ucID").equalTo(self.uc.id).on('value',function(snapshot) {
      self.metas = {};
      self.qtdeMetas = 0;
      var metas = snapshot.val();
      if(metas != null) {
        self.metas = metas;
        self.qtdeMetas = Object.keys(metas).length;
      }
      console.log("self.metas",self.metas);
    });

  },
  methods:{

    build() {
      var self = this;
    },

    exportarPlanilha() {
      console.log("exportarPlanilha");
      console.log("this.uc",this.uc);
      console.log("this.conteudos",this.conteudos);
      console.log("this.topicos",this.topicos);
      console.log("this.metas",this.metas);

      var vet = [];
      vet.push({
        codigo: this.uc.ucCOD,
        sigla: this.uc.ucSGL,
        nome: this.uc.nome,
        certificacao: this.uc.certificacao.texto,
        biblioBas01: 'BIB-0003-BERN-LAUT-2011',
        biblioBas02: 'BIB-0002-ALVE-2017',
        biblioBas03: 'BIB-0233-SOAR-2014',
        biblioCom01: 'BIB-0004-RIBEIRO-1998',
        biblioCom02: 'BIB-0005-FULV-2013',
        biblioCom03: 'BIB-0025-SILV-CA-2016',
        biblioCom04: 'BIB-0015-NUAN-2020',
        biblioCom05: 'BIB-0334-ROJAS-RB-2019',
      });

      console.log("vet",vet);
      const fileName = "appIntegra-TESTE-"+moment().format()+".xlsx";
      var ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
      var wb = XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Unidades Curriculares");

      vet = [];
      var idx = 0;
      for(var key in this.conteudos) {
        idx++;
        vet.push({
          codigo: this.conteudos[key].id,
          ordem: idx,
          conteudoEmenta: this.conteudos[key].texto,
          codigoUC: this.uc.ucCOD,
          siglaUC: this.uc.ucSGL,
          nomeUC: this.uc.nome,
        });
      }

      ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
      XLSX.utils.book_append_sheet(wb, ws, "Ementas");

      vet = [];
      idx = 0;
      for(var key in this.topicos) {
        idx++;
        vet.push({
          codigo: this.topicos[key].id,
          ordem: idx,
          topicoGerador: this.topicos[key].texto,
          codigoUC: this.uc.ucCOD,
          siglaUC: this.uc.ucSGL,
          nomeUC: this.uc.nome,
        });
      }

      ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
      XLSX.utils.book_append_sheet(wb, ws, "Topicos geradores");

      vet = [];
      idx = 0;
      for(var key in this.metas) {
        idx++;
        vet.push({
          id: this.metas[key].id,
          codigo: this.metas[key].metaCodigo,
          ordem: idx,
          nivel: this.metas[key].nivel,
          meta: this.metas[key].texto,
          codigoUC: this.uc.ucCOD,
          siglaUC: this.uc.ucSGL,
          nomeUC: this.uc.nome,
        });
      }

      ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
      XLSX.utils.book_append_sheet(wb, ws, "Metas de Compreensao");

      vet = [];
      idx = 0;
      for(var key in this.uc.competencias) {
        idx++;
        var newKey = "COMP";
        var num = ""+(26+idx);
        while (num.length < 5) num = "0" + num;
        newKey = newKey + "-" + num;
        vet.push({
          codigo: newKey,
          ordem: idx,
          competencia: this.uc.competencias[key].texto,
          codigoUC: this.uc.ucCOD,
          siglaUC: this.uc.ucSGL,
          nomeUC: this.uc.nome,
        });
      }

      ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
      XLSX.utils.book_append_sheet(wb, ws, "Competencias");

      vet = [];
      vet.push({
        codigo: 'BIB-0003-BERN-LAUT-2011',
        tipoPub: 'Livros eletrônicos',
        sobrenome1: 'BÊRNI',
        nome1: 'Duilio de Avila',
        sobrenome2: 'LAUTERT',
        nome2: 'Vladimir',
        sobrenome3: '',
        nome3: '',
        etAl: 'não',
        titulo: 'Mesoeconomia',
        subTitulo: 'lições de contabilidade social: a mensuração do esforço produtivo da sociedade',
        edicao: '',
        local: 'Porto Alegre',
        editora: 'Bookman',
        anoPub: '2011',
        eBook: 'sim',
        disponivelEm: 'https://integrada.minhabiblioteca.com.br/books/9788540700550'
      });

      vet.push({
        codigo: 'BIB-0005-BJAS-RBCA-2010',
        tipoPub: 'Coleção de publicação periódica em meio eletrônico',
        sobrenome1: '',
        nome1: '',
        sobrenome2: '',
        nome2: '',
        sobrenome3: '',
        nome3: '',
        etAl: 'não',
        titulo: '',
        subTitulo: '',
        revista: 'BRAZILIAN JOURNAL OF AGRICULTURAL SCIENCES / REVISTA BRASILEIRA DE CIÊNCIAS AGRÁRIAS',
        instituicao: 'Universidade Federal Rural de Pernambuco',
        edicao: '',
        local: 'Recife',
        editora: '',
        anoPub: '2010',
        eBook: 'não',
        online: 'sim',
        ISSN: '1981-1160',
        disponivelEm: 'http://search.ebscohost.com/login.aspx?direct=true&db=aph&jid=B070&lang=pt-br&site=ehost-live',
        acesso: '21 fev.2020'
      });

      vet.push({
        codigo: 'BIB-0004-RIBEIRO-1998',
        tipoPub: 'Coleção de publicação periódica em meio eletrônico',
        tipo: 'COMPLEMENTAR',
        sobrenome1: 'RIBEIRO',
        nome1: 'P. S. G.',
        sobrenome2: '',
        nome2: '',
        sobrenome3: '',
        nome3: '',
        etAl: 'não',
        titulo: 'Adoção à brasileira',
        subTitulo: 'uma análise sociojurídica',
        periodico: 'Dataveni@',
        instituicao: '',
        edicao: '',
        local: 'São Paulo',
        editora: '',
        anoPub: '1998',
        mesPub: 'ago',
        anoRel: '3',
        numero: '18',
        eBook: 'não',
        online: 'sim',
        ISSN: '1981-1160',
        disponivelEm: 'http://www.datavenia.inf.br/frame.artig.html',
        acesso: '10 set. 1998'
      });

      ws = XLSX.WorkSheet = XLSX.utils.json_to_sheet(vet);
      XLSX.utils.book_append_sheet(wb, ws, "Bibliografias");

      XLSX.writeFile(wb, fileName);


    }
  }
}
</script>

<style scoped>
</style>
